import React, { useEffect, useState } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import tdcColors from '@tdcerhverv/parrotfish/dist/colors-design-token.js'
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Hidden,
} from '@material-ui/core'
import { Tabs } from '../components/Tabs/Tabs'
import { StockStatusIndicator } from '../components/StockStatusIndicator/StockStatusIndicator'
import { translate } from '../helpers/translate'
import { useBasket } from '../modules/basket'
import { useProductList } from '../store/product-list'
import { GetHelp } from '../components/GetHelp'
import { ImageGallery } from '../components/ImageGallery'
import { VariantSelector } from '../components/VariantSelector'
import { routes } from '../components/Router/routes'
import Layout from '../Layout/Layout'
import Seo from '../components/Seo'
import { getErhvervsCenterAPIUrl } from '../helpers/api-url'
import { usePageViewAnalytics } from '../hooks/useAnalytics'

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  root: {
    position: 'relative',
  },
  rootLoading: {
    '&:after': {
      position: 'absolute',
      zIndex: 10,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: 'rgba(255,255,255,.5)',
      content: "''",
    },
  },
  galleryContainer: {
    [breakpoints.up('sm')]: {
      order: 0,
    },
  },
  mainInfoContainer: {
    [breakpoints.up('sm')]: {
      order: 1,
    },
  },
  brandName: {
    color: tdcColors.greyMediumDark,
  },
  productName: {
    ...typography.h3,
    marginTop: 0,
    marginBottom: spacing(1.75),
  },
  priceLabel: {
    color: tdcColors.greyMediumDark,
  },
  tableSpecNameCell: {
    width: '40%',
  },
  tableSpecCellOdd: {},
  tableSpecCellEven: {
    background: tdcColors.greyLightest,
  },
}))

const ProductViewTemplate = ({ data }) => {
  const {
    Brand,
    Delivery,
    Description,
    FeatureGroups,
    Price,
    FormattedPrice,
    Images,
    Name,
    VariantNo,
    Variants,
    Networks,
  } = data.product
  const classes = useStyles()
  const { serializedQueryString } = useProductList()
  const productBrand = Brand.charAt(0) + Brand.slice(1).toLowerCase()

  //   const { productId = '' } = useParams<{ [key in Params]: string }>();
  const productId = VariantNo //probably not correct to use like this
  const { addToBasket, openMiniBasket } = useBasket(state => ({
    addToBasket: state.addToBasket,
    openMiniBasket: state.openMiniBasket,
  }))

  const lines = useBasket(state => state.lines || [])
  const [delivery, setDelivery] = useState(Delivery)
  const [quantityInBasket, setQuantityInBasket] = useState(
    lines.find(({ variantId }) => variantId === productId)?.amount || 0,
  )

  useEffect((): void => {
    setQuantityInBasket(
      lines.find(({ variantId }) => variantId === productId)?.amount || 0,
    )
  }, [lines, productId])

  useEffect(() => {
    // update delivery status in case product is sold out or restocked since last gatsby build
    fetch(`${getErhvervsCenterAPIUrl()}publicproducts/${VariantNo}`).then(res =>
      res.json().then(product => {
        setDelivery(product.Delivery)
      }),
    )
  }, [])

  const changeVariant = (idx: number, variantID: string): void => {
    const currentVariant = Variants?.find(
      variant => variant.VariantNo === productId,
    )?.VariantName

    const newlyChosenVariant = (Variants || [])[idx]

    // We'll try replacing the current color with the newly selected one, which should yield the correct URL for us.
    const processedName =
      currentVariant && newlyChosenVariant && newlyChosenVariant.VariantName
        ? (Name || '').replace(currentVariant, newlyChosenVariant.VariantName)
        : Name

    // Calculate new URL for the given variant ID
    const newPath = routes.productPage.build(variantID, processedName)

    // Replace the current location with the new URL for a seamless transition
    navigate(newPath)
  }

  const currentVariantIndex = (Variants || []).findIndex(
    variantData => variantData.VariantNo === productId,
  )

  // Adobe Analytics - Global Variables
  usePageViewAnalytics({
    trackingPageInfo: {
      pageName: `${Brand} ${Name}`,
      siteSection: 'shop',
      subSection1: 'product',
      pageType: 'product detail',
      id: `product-${VariantNo}`,
      pageVariant: 'SMB',
    },
    trackingProductsInfo: {
      customEvent: 'view_item',
      items: [
        {
          brand: productBrand,
          name: Name,
          id: VariantNo,
          quantity: `${quantityInBasket}`,
          price: Price,
        },
      ],
    },
    dependency: [VariantNo],
  })

  const BackToListButton = () => (
    <Button
      component={Link}
      to={`/${serializedQueryString ? `?${serializedQueryString}` : ''}`}
      variant="text"
      size="large"
    >
      {translate('backToList')}
    </Button>
  )

  const ProductDetails = () => (
    <>
      <Typography variant="h6" className={classes.brandName} component="div">
        {Brand}
      </Typography>
      <Typography variant="h3" component="h1" className={classes.productName}>
        {Name}
      </Typography>
      {delivery && (
        <Box mb={2}>
          <StockStatusIndicator color={delivery.Status} text={delivery.Text} />
        </Box>
      )}
      <VariantSelector
        variants={Variants || []}
        setSelectedIndex={changeVariant}
        selectedIndex={Math.max(0, currentVariantIndex)}
        large
      />
    </>
  )
  const PriceDisplay = () => (
    <>
      <Typography variant="h5" component="div">
        {FormattedPrice}
      </Typography>
      <Typography variant="caption" gutterBottom className={classes.priceLabel}>
        {translate('exVatLabel')}
      </Typography>
    </>
  )
  const CallToAction = () => (
    <Grid container justifyContent="flex-end" spacing={2}>
      <Grid item>
        <BackToListButton />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          disabled={
            delivery?.StockCount === 0 ||
            delivery?.StockCount === quantityInBasket
          }
          onClick={() => {
            addToBasket(productId, 1)
            openMiniBasket()
          }}
          color="primary"
          size="large"
        >
          {delivery?.StockCount === 0
            ? translate('basket.outOfStock')
            : translate('basket.addToBasket')}
        </Button>
      </Grid>
    </Grid>
  )
  const RenderedImageGallery = () => (
    <ImageGallery
      has5GBadge={Networks.includes('5G')}
      variantNo={VariantNo}
      images={Images}
    />
  )

  const DescriptionContent = (): React.ReactNode => {
    return !!Description ? (
      <Typography
        dangerouslySetInnerHTML={{
          __html: Description.replaceAll(/\n/g, '<br/>'),
        }}
        variant="body2"
        component="div"
      />
    ) : (
      <></>
    )
  }

  const hasSpecifications = FeatureGroups?.length > 0
  const SpecificationContent = () => {
    return hasSpecifications ? (
      <Box>
        {FeatureGroups.map((group, gIdx) => (
          <React.Fragment key={gIdx}>
            <Typography variant="subtitle1">{group.Name}</Typography>
            <Box mb={4} mt={2}>
              <TableContainer component={Paper} variant={'outlined'}>
                <Table aria-label={group.Name} size="small">
                  <TableBody>
                    {group.Specifications?.map((specification, sIdx) => (
                      <TableRow key={`${specification.Name}-${sIdx}`}>
                        <TableCell
                          component="th"
                          className={`${classes.tableSpecNameCell} ${
                            sIdx % 2
                              ? classes.tableSpecCellEven
                              : classes.tableSpecCellOdd
                          }`}
                        >
                          <Typography variant="subtitle2">
                            {specification.Name}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={
                            sIdx % 2
                              ? classes.tableSpecCellEven
                              : classes.tableSpecCellOdd
                          }
                        >
                          <Typography variant="body2">
                            {specification.Value}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    ) : (
      <></>
    )
  }

  return (
    <Layout>
      <Seo
        lang="da"
        title={`${Brand} ${Name}`}
        description="TDC Erhverv shop"
      />
      <article
        className={classes.root} /* ${loading ? ` ${classes.rootLoading} */
      >
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={12} sm={6} className={classes.mainInfoContainer}>
            <ProductDetails />
            <Box mb={4} mt={4}>
              <PriceDisplay />
              <Box mt={4} mb={10}>
                <Typography
                  variant="caption"
                  gutterBottom
                  className={classes.priceLabel}
                >
                  {`${translate('sku')} ${VariantNo}`}
                </Typography>
              </Box>
              <CallToAction />
              <Box mt={5} mb={5}>
                <GetHelp wide />
              </Box>

              <Hidden smUp>
                <Box mb={5}>
                  <RenderedImageGallery />
                </Box>
              </Hidden>
              {!!Description && hasSpecifications && (
                <Tabs
                  tabs={[
                    {
                      title: translate('product.description'),
                      content: DescriptionContent(),
                    },
                    {
                      title: translate('product.specifications'),
                      content: SpecificationContent(),
                    },
                  ]}
                  idPrefix={'productTabs'}
                />
              )}
            </Box>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={5} className={classes.galleryContainer}>
              <RenderedImageGallery />
            </Grid>
          </Hidden>
        </Grid>
        <BackToListButton />
        {/* //TODO: Figure out WTF is this about */}
        {/* <script type="application/ld+json">{JSON.stringify(jsonLDPayload)}</script> */}
      </article>
    </Layout>
  )
}

export default ProductViewTemplate

// export const productViewQuery = graphql`
//   query productAndProduct($variantNo: String!) {
//     product(VariantNo: { eq: $variantNo }) {
//       Brand
//       Delivery {
//         Status
//         StockCount
//         Text
//       }
//       Description
//       FeatureGroups {
//         Name
//         Specifications {
//           Name
//           Value
//         }
//       }
//       FormattedPrice
//       Images {
//         Url
//       }
//       IncludedInSubscription
//       Name
//       Networks
//       Price
//       ProductBundleLineId
//       VariantNo
//       Variants {
//         VariantName
//         VariantNo
//         Url
//         IsFavorite
//         IsEligibleForGrant
//         ColorCode
//       }
//       Vat
//     }
//   }
// `
