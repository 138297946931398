import React from 'react';
import { Box, Tab, Typography, Tabs as MuiTabs, makeStyles } from '@material-ui/core';
import tdcColors from '@tdcerhverv/parrotfish/dist/colors-design-token.js';

type TabPanelProps = {
    children?: React.ReactNode;
    idPrefix: string;
    index: string | number;
    value: string | number;
};

type SingleTabProps = {
    title: string;
    content: React.ReactNode;
};

type TabContainerProps = {
    tabs: SingleTabProps[];
    idPrefix: string;
    initialIndex?: number;
};

const SingleTab = (props: TabPanelProps) => {
    const { children, value, index, idPrefix, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${idPrefix}-${index}`}
            aria-labelledby={`${idPrefix}-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box mt={4} mb={4}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        color: tdcColors.greyMediumDark,
    },
    tab: {
        ...theme.typography.subtitle1,
    },
    selectedTab: {
        color: tdcColors.black,
    },
}));

export const Tabs = ({ tabs, idPrefix, initialIndex = 0 }: TabContainerProps): JSX.Element => {
    const [selectedTab, setSelectedTab] = React.useState(initialIndex);
    const classes = useStyles();

    const handleTabChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <Box>
            <MuiTabs value={selectedTab} indicatorColor="primary" textColor="inherit" onChange={handleTabChange}>
                {tabs.map((tab, index) => (
                    <Tab
                        label={tab.title}
                        key={index}
                        id={`${idPrefix}-tab-${index}`}
                        aria-controls={`${idPrefix}-${index}`}
                        classes={{
                            wrapper: classes.tab,
                            selected: classes.selectedTab,
                            root: classes.root,
                        }}
                    />
                ))}
            </MuiTabs>
            {tabs.map((tab, index) => (
                <SingleTab index={index} key={index} idPrefix={idPrefix} value={selectedTab}>
                    {tab.content}
                </SingleTab>
            ))}
        </Box>
    );
};
