import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import ProductViewTemplate from '../../templates/ProductViewTemplate'
import { getErhvervsCenterAPIUrl } from '../../helpers/api-url'

enum Status {
  LOADING = 'LOADING',
  RESULTS = 'RESULTS',
  ERROR = 'ERROR',
}
const { LOADING, RESULTS, ERROR } = Status

const BackupProduct = props => {
  console.log('fallback') //keep this for debugging in prod
  const { productId } = props
  const [product, setProduct] = useState()
  const [status, setStatus] = useState<Status>(LOADING)
  useEffect(() => {
    fetch(`${getErhvervsCenterAPIUrl()}publicproducts/${productId}`)
      .then(res => {
        if (res.status !== 200) return setStatus(ERROR)
        res.json().then(data => {
          setProduct(data)
          setStatus(RESULTS)
        })
      })
      .catch(error => {
        console.error('Fetch error:', error)
        setStatus(ERROR)
      })
  }, [productId])

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      {status === RESULTS && <ProductViewTemplate data={{ product }} />}
      {status === LOADING && <CircularProgress />}
      {status === ERROR && window.location.replace('/404')}
    </>
  )
}

export default BackupProduct
