import React, { useEffect, useState } from 'react'
import {
  galleryThumbnailTargetSize,
  getImageURLForGalleryThumbnail,
  getImageURLForGalleryView,
} from '../../helpers/image'
import { makeStyles } from '@material-ui/core'
import { ImageSitecoreItem } from '../../types/generated/sitecore.interface'
import { ImageBadge } from '../ImageBadge'
import { Icon5G } from '../../icons/Icon5'
import tdcColors from '@tdcerhverv/parrotfish/dist/colors-design-token.js'
type IImageGalleryProps = {
  images: ImageSitecoreItem[]
  variantNo: string
  has5GBadge: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  largeImageWrapper: {
    position: 'relative',
    maxWidth: '500px',
    margin: 'auto',
    paddingTop: '100%', // Mmmm, 1:1 ratio!
  },
  thumbnailButton: {
    position: 'relative',
    appearance: 'none',
    margin: 0,
    width: galleryThumbnailTargetSize,
    height: galleryThumbnailTargetSize,
    padding: 0,
    background: 'none',
    cursor: 'pointer',

    [theme.breakpoints.up('sm')]: {
      width: galleryThumbnailTargetSize,
      height: galleryThumbnailTargetSize,
    },
  },
  image: {
    position: 'absolute',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',

    '&.thumb': {
      maxWidth: '80%',
      maxHeight: '80%',
    },
  },
  icon5g: {
    fill: theme.palette.primary.light,
  },
  thumbnailList: {
    listStyle: 'none',
    margin: '1em 10%',
    padding: 0,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  thumbnailItem: {
    display: 'block',
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: 0,
    paddingBottom: theme.spacing(1.75),
    borderBottom: `1px solid ${tdcColors.greyLightest}`,

    '&.active': {
      borderColor: theme.palette.primary.main,
    },
  },
}))

export const ImageGallery: React.FC<IImageGalleryProps> = React.memo(
  ({ images, has5GBadge }: IImageGalleryProps): JSX.Element => {
    if (typeof window !== 'object') return <></>
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const classes = useStyles()

    useEffect((): void => {
      if (selectedImageIndex - 1 > images.length) {
        setSelectedImageIndex(images.length - 1)
      }
    }, [images.length])

    if (!images || !images.length) return <></>

    const activeImage = images[selectedImageIndex]

    return (
      <div className={classes.root}>
        <div className={classes.largeImageWrapper}>
          <ImageBadge
            badge={<Icon5G className={classes.icon5g} />}
            isVisible={has5GBadge}
          >
            <img
              src={getImageURLForGalleryView(activeImage)}
              srcSet={`${getImageURLForGalleryView(
                activeImage,
              )}, ${getImageURLForGalleryView(activeImage, true)} 2x`}
              loading="lazy"
              className={classes.image}
              alt={activeImage.AltText || ''}
            />
          </ImageBadge>
        </div>

        {images.length > 1 && (
          <ul className={classes.thumbnailList}>
            {images.map((image, idx) => (
              <li
                key={image.Url}
                className={`${classes.thumbnailItem}${
                  selectedImageIndex === idx ? ` active` : ''
                }`}
              >
                <button
                  onClick={({ currentTarget }) => {
                    currentTarget.blur()
                    setSelectedImageIndex(idx)
                  }}
                  className={classes.thumbnailButton}
                >
                  <img
                    className={`${classes.image} thumb`}
                    src={getImageURLForGalleryThumbnail(image)}
                    srcSet={`${getImageURLForGalleryThumbnail(
                      image,
                    )}, ${getImageURLForGalleryThumbnail(image, true)} 2x`}
                    loading="lazy"
                    alt={image.AltText || ''}
                  />
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  },
)
