import React from 'react';
import { makeStyles } from '@material-ui/core';

type ImageBadgeProps = {
    descripton?: string;
    badge?: JSX.Element;
    children: JSX.Element;
    isVisible?: boolean;
};

const useStyles = makeStyles(() => ({
    badge: {
        position: 'absolute',
        top: '0',
        left: '90%',
        'z-index': '1',
        transform: 'scale(3,3)',
        'transform-origin': 'right top',
    },
}));

export const ImageBadge: React.FC<ImageBadgeProps> = React.memo(
    ({ badge, children, isVisible = false }: ImageBadgeProps): JSX.Element => {
        const classes = useStyles();
        const Badge = (): JSX.Element => {
            if (isVisible && badge) {
                return <div className={classes.badge}>{badge}</div>;
            }
            return <></>;
        };
        return (
            <>
                <Badge />
                {children}
            </>
        );
    },
);
